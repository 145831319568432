.anketa-wrapper .anketa__page {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
.anketa-wrapper .anketa__left {
    flex-grow: 2;
    margin: 0 0 80px 0;
}
.anketa-wrapper .anketa__right {
    flex-grow: 1;
}

.anketa-wrapper .anketa__title {
    font-family: "Muller Bold","Nunito Sans",sans-serif;
    margin: 0 0 48px;
    font-size: 2em;
    font-weight: 700;
    line-height: 1;
}
.anketa-wrapper .anketa__foto {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.anketa-wrapper .anketa__img {
    width: 120px;
    height: 120px;
    margin: 0 0 16px 0;
}
.anketa-wrapper .anketa__img .ant-avatar {
    width: 100%;
    height: 100%;
}
.anketa-wrapper .anketa__img .ant-avatar .anticon {
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 80px;
    line-height: 120px;
    font-weight: 300;
    text-align: center;
}
.anketa-wrapper .anketa__img .ant-avatar .anticon svg {
    line-height: 120px;
    margin: 0 auto;
}
.anketa-wrapper .anketa__btn-group {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.anketa-wrapper .anketa__btn-group button {
    margin: 0 0 0 16px;
}

.anketa-wrapper .react-tel-input .form-control {
    padding: 11px 20px 8px 48px;
    border: 1px solid #2d4d4c;
    border-radius: 5px;
    font-size: 14px;
    color: #2D4D4C;
    background-color: inherit;
}

.anketa-wrapper .react-tel-input .selected-flag {
    background-color: unset;
}
.anketa-wrapper .react-tel-input .flag-dropdown {
    background-color: unset;
}

.anketa-wrapper .anketa__form-wrapper .anketa__input-hor {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 24px 0;
}

.anketa-wrapper .anketa__form-wrapper .anketa__input-hor .ant-form-item-label {
    width: 280px;
    padding: 0 24px 0 0;
}
.anketa-wrapper .anketa__form-wrapper .anketa__input-hor .ant-form-item-label > label {
    position: relative;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #2D4D4C;
    padding: 0 0 0 10px;
}
.anketa-wrapper .ant-picker-input > input {
    font-weight: 400;
    color: #2D4D4C;
}
.anketa-wrapper .anketa__form-wrapper .anketa__input-hor.anketa__input_input input {
    width: 330px;
    padding: 11px 20px 8px 11px;
    border: 1px solid #2d4d4c;
    border-radius: 5px;
    background-color: inherit;
    color: #2D4D4C;
}
.anketa-wrapper .anketa__form-wrapper .anketa__input-hor.anketa__input_timezone .ant-form-item-label {
    width: 280px;
    padding: 0 24px 0 0;
    line-height: 45px;
    font-weight: 600;
    color: #2D4D4C;
}


.anketa__input_fullage .ant-input-number {
    width: auto;
}
.anketa-wrapper .anketa__form-wrapper .anketa__input_fullage.anketa__input-hor.anketa__input_input input {
    width: 50px;
    padding: 11px 11px 8px 11px;
}

.anketa-wrapper .anketa__form-wrapper .anketa__input_period .ant-form-item-control {
    min-width: 200px;
}
.anketa-wrapper .anketa__form-wrapper .anketa__input_period .ant-select-selection-overflow {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.anketa-wrapper .anketa__form-wrapper .anketa__input_format .ant-form-item-control {
    width: 200px;
}

.anketa-wrapper .anketa__input_select .ant-form-item-control {
    width: 300px;
}

.anketa-wrapper .anketa__form-wrapper .anketa__input-hor .ant-form-item-control {
    flex-grow: unset;
}




.anketa-wrapper .anketa__form-wrapper .anketa__input-hor.anketa__input_timezone .ant-form-item-control .ant-select-selector {
    padding: 11px 20px 8px 11px;
    border: 1px solid #2d4d4c;
    border-radius: 5px;
    background-color: inherit;
}
.anketa-wrapper .anketa__form-wrapper .anketa__input-hor.anketa__input_timezone .ant-form-item-control .ant-select-selection-item {
    line-height: 30px;
    font-size: 14px;
    color: #2d4d4c;
}
.anketa-wrapper .anketa__input_lang .ant-select-selector {
    border: 1px #2d4d4c solid !important;
    background-color: unset !important;
}
.anketa-wrapper .anketa__form-wrapper .anketa__input-hor.anketa__input_age .ant-picker {
    width: 170px;
    border: 1px solid #2d4d4c;
    border-radius: 5px;
    background-color: inherit;
    font-size: 1em;
    font-weight: 400;
    padding: 11px 20px 8px 11px;
}
.anketa-wrapper .anketa__form-wrapper .anketa__input-hor.anketa__input_age .ant-picker-suffix {
    margin: 0;
    right: 14px;
}
.anketa-wrapper .anketa__form-wrapper .anketa__input-hor.anketa__input_gender .ant-select {
    width: 140px;
    border: 1px solid #2d4d4c;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    color: #2d4d4c;
}
.anketa-wrapper .anketa__form-wrapper .anketa__input-hor.anketa__input_gender .ant-select-selection-search {
    width: 100%;
    padding: 11px 20px 8px 11px;
}
.anketa-wrapper .anketa__form-wrapper .anketa__input-hor.anketa__input_gender .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 0;
    right: 0;
}
.anketa-wrapper .anketa__form-wrapper .anketa__input-hor.anketa__input_gender .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: inherit;
}
.anketa-wrapper .anketa__form-wrapper .anketa__input-hor .ant-select-selec {
    width: 437px;
    padding: 11px 20px 8px 11px;
    background-color: inherit;
}
.anketa-wrapper .anketa__email {
    width: 870px;
}
.anketa-wrapper .anketa__email .ant-col-20 {
    flex: auto;
    max-width: 100%;
}
.anketa-wrapper .anketa__form-wrapper .anketa__input-hor.anketa__input_email .ant-input {
    background-color: inherit;
    width: 260px;
    padding: 11px 20px 8px 11px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #2d4d4c;
    border: 1px solid #2d4d4c;
    border-radius: 5px;
}

.anketa-wrapper .ant-form-item-has-error .ant-input-number-focused, .anketa-wrapper .ant-form-item-has-error .ant-picker-focused, .anketa-wrapper .ant-form-item-has-error .ant-input-number:focus, .anketa-wrapper .ant-form-item-has-error .ant-picker:focus {
    border-color: #ff4d4f;
    border-right-width: 2px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(245 34 45 / 20%);
}


.anketa-wrapper .anketa__text {
    width: 100%;
}
.anketa-wrapper .anketa__text .ant-form-item-label, .anketa-wrapper .anketa__text .ant-form-item-control {
    width: 100%;
}
.anketa-wrapper .anketa__subtitle {
    margin: 0 0 8px 0;
}
.anketa-wrapper .anketa__text .ant-form-item-label label, .anketa-wrapper .anketa__subtitle {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 600;
    color: #2D4D4C;
}
.anketa-wrapper .anketa__text .ant-form-item-label label {
    position: relative;
    padding: 0 0 0 10px;
}
.anketa-wrapper textarea.anketa__textarea {
    width: 100%;
    min-height: 200px;
    line-height: 1.4;
    background-color: #EEECE8;
    padding: 11px 20px 8px 11px;
    border: 1px solid #2d4d4c;
}
.anketa-wrapper textarea.anketa__textarea__main-educ {
    min-height: 40px;
}
.anketa-wrapper .anketa__drugblock {
    width: 100%;
    margin: 0 0 32px 0;
}
.anketa-wrapper .drugblock__load-files {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin: 0 0 8px 0;
}
.anketa-wrapper .drugblock__load-files > div {
    margin: 0 8px 0 0;
}
.anketa-wrapper .drugblock__load-files_item {
    font-size: 16px;
    font-weight: 700;
}
.anketa-wrapper .ant-input-number {
    background-color: unset;
}
.anketa-wrapper .anketa__form-wrapper .ant-input-number-input {
    height: auto;
}
.anketa-wrapper .anketa__form-wrapper .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    position: absolute;
    top: 0;
    left: 0;
}

.anketa-wrapper .ant-form-item-has-error .ant-input-number, .anketa-wrapper .ant-form-item-has-error .ant-picker {
    border: 0;
}
.anketa-wrapper .anketa__form-wrapper .ant-form-item-has-error.anketa__input-hor.anketa__input_input input {
    border-color: #ff4d4f;
}
.anketa-wrapper .ant-form-item-has-error .ant-input-number:not([disabled]):hover, .anketa-wrapper .ant-form-item-has-error .ant-picker:not([disabled]):hover {
    background-color: unset;
}

@media screen and (max-width: 900px) {
    .anketa__left {
        width: 100%;
    }
    .anketa-wrapper .anketa__form-wrapper .anketa__input-hor.anketa__input_input input, .anketa-wrapper .anketa__form-wrapper .anketa__input-hor.anketa__input_email .ant-input, .anketa-wrapper .anketa__email {
        width: 100%;
    }
    .anketa-wrapper .anketa__form-wrapper .anketa__email .anketa__input-hor {
        margin: 0 0 8px 0;
    }
    .anketa-wrapper .anketa__form-wrapper .anketa__email {
        margin: 0 0 16px 0;
    }
}
