.psychoListPageMobile-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #e5e3dc;
    padding-bottom: 80px;
}

.psychoListPageMobile-subheader {
    position: relative;
    z-index: 1;
    padding: 16px 16px 40px 16px;
    font-size: 20px;
    line-height: 1.25;
    text-align: center;
    color: #e5e3dc;
    font-weight: 700;
    background-color: #2d4d4c;
    border-radius: 16px 16px 0 0;
}
.psychoListPageMobile-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    z-index: 2;
    width: 100%;
    overflow-y: scroll;
    margin: -20px 0 0 0;
    padding: 52px 40px 20px 40px;
    background-color: #e5e3dc;
    border-radius: 16px 16px 0 0;
}

.psychoListPageMobile-body::-webkit-scrollbar {
    display: none;
}

.psychoListPageMobile-card-wrapper {
    background-color: #ffffff;
    border-radius: 5px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 0;
    align-items: center;
}

.psychoListPageMobile-card-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 36px 0;
    padding: 0 24px;
}
.btn-row__mobile__padding {
    margin: 0 0 24px 0;
}
.psychoListPageMobile-card-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 5px;
    text-align: center;
}
.psychoListPageMobile-card-title {
    width: 270px;
    margin: 0 0 2px 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    color: #333;
}
.psychoListPageMobile-card-gestalt {
    margin: 0 0 30px 0;
    font-size: 12px;
    line-height: 13px;
    font-weight: 400;
    color: #666666;
}
/* Some Param: exp, age, cost */
.psychoListPageMobile-card-column-icon {
    height: 23px;
    margin: 0 0 8px 0;
}
.psychoListPageMobile-card-column-label {
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
}
.psychoListPageMobile-card-column-value {
    font-size: 12px;
    line-height: 1;
}

.psychoListPageMobile-card-img {
    width: 104px;
    height: 104px;
    margin-bottom: 16px;
    border-radius: 50%;
    overflow: hidden;
    background: #8d8d8d;
}
.psychoListPageMobile-card-logo {
    width: 100%;
    height: 100%;
}
.psychoListPageMobile-body-button-primary {
    color: #ffffff;
    background-color: #EE836F;
    padding: 0px 10px;
    border-radius: 10px;
    font-weight: bold;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}

.psychoListPageMobile-body-button-default {
    color: #2d4d4c;
    border: 1px solid #2d4d4c;
    margin-right: 5px;
    background-color: #ffffff;
    padding: 0px 10px;
    border-radius: 10px;
    font-weight: bold;
    word-wrap: break-word;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.2;
}

.psychoListPageMobile-body-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 21px; 
}

.psychoListPageMobile-body-pagination-circle {
        background: #8d8d8d;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        cursor: pointer;
        margin: 0px 4px;
}

.psychoListPageMobile-body-pagination-circle-active {
        background: #2d4d4c;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        cursor: pointer;
        margin: 0px 4px;
}