.adminSpecialistsPageDesktop-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px 80px;
    height: 90vh;
}

.adminSpecialistsPageDesktop-row {
    display: flex;
    flex-direction: row;
    max-width: 90vw;
    flex-wrap: wrap;
}