.profileSpecialist-mobile-wrapper {
    background-color: #2d4d4c;
    border-radius: 16px 16px 0px 0px;
    color: #ffffff;
}

.profileSpecialist-mobile-header-row {
    padding: 32px 16px 40px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profileSpecialist-mobile-header-logo {
    background: #8d8d8d;
    width: 65px;
    height: 65px;
    border-radius: 50%;
}

.profileSpecialist-mobile-header-name {
    width: calc(100% - 85px);
    padding: 0 40px 0 0;
    font-size: 20px;
    line-height: 1.25;
    margin-left: 20px;
}

.profileSpecialist-mobile-body-wrapper {
    background-color: #e5e3dc;
    border-radius: 16px 16px 0px 0px;
    color: #2d4d4c;
    padding: 32px 16px 0 16px;
    display: flex;
    flex-direction: column;
}
.profileSpecialist-mobile-body-h2 {
    margin: 16px 0 16px 0;
}
.profileSpecialist-mobile-body-wrapper .profile-input-block {
    display: block;
}
.profileSpecialist-mobile-body-wrapper .profile-input-block .profileLabel {
    margin: 0 0 8px 0;
}
.profileSpecialist-mobile-body-row-label {
    font-size: 14px;
    line-height: 1;
    font-weight: 700;
}
.profileSpecialist-mobile-body-col-label {
    margin: 0 0 16px 0;
    font-size: 14px;
    line-height: 1;
    font-weight: 700;
}
.profileSpecialist-mobile-body-row {
    width: 100%;
    margin: 0 0 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.profileSpecialist-mobile-body-col {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
.profileSpecialist-mobile-body-row__padding .btn-default {
    padding: 12px 14px;
}
.profileSpecialist-mobile-body-row-value {
    text-align: right;
    text-overflow: ellipsis;
    max-width: 40vw;
    overflow: hidden;
    white-space: nowrap;
}

.profileSpecialist-mobile-body-button-primary {
    color: #ffffff;
    /* background-color: #EE836F; */
    padding: 0px 10px;
    width: 42vw;
    border-radius: 0;
    font-weight: bold;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ffffff;
    cursor: pointer;
}

.profileSpecialist-mobile-body-button-default {
    color: #8d8d8d;
    /* background-color: #ffffff; */
    padding: 0px 10px;
    border-radius: 0;
    font-weight: bold;
    word-wrap: break-word;
    width: 42vw;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.2;
    border-bottom: 1px solid #8d8d8d;
    cursor: pointer;
}
.profileSpecialist-mobile-body-button-primary:hover, .profileSpecialist-mobile-body-button-default:hover {
    color: #fff;
    border-bottom: 1px solid #fff;
}

.profileSpecialist-mobile-body-row-value-wrapper {
    display: flex;
    flex-direction: row;
}

.profileSpecialist-mobile-header-edit {
    height: 20px;
    width: 20px;
    margin-left: 10px;
}

.profileSpecialist-mobile-select { 
    border: none;
    width: 50vw;
    border-radius: 0;
    background-color: #e5e3dc;
    border-bottom: 1px solid;
}

.profileSpecialist-mobile-select > select { 
    -webkit-appearance: none;
    -moz-appearance: none; 
    appearance: none; 
    position: absolute; 
    top: -9999px; 
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
 }

.profileSpecialist-mobile-textarea {
    font-size: 14px;
    font-weight: 300;
}
.profileSpecialist-mobile-textarea p {
    margin: 0 0 8px 0;
}

  /* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
  
.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
  
input:checked + .slider {
    background-color: #2196F3;
}
  
input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}
  
input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
  
  /* Rounded sliders */
.slider.round {
    border-radius: 34px;
}
  
.slider.round:before {
    border-radius: 50%;
}

  .profileSpecialist-mobile-paymentType-radio-transparent {
      padding: 10px;
      border: 1px solid #8D8D8D;
      color: #2D4D4C;
      cursor: pointer;
  }

  .profileSpecialist-mobile-paymentType-radio-green {
    padding: 10px;
    border: 1px solid #8D8D8D;
    color: #fff;
    background-color: #2D4D4C;
    cursor: pointer;
  }

  .payment-block-column {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
  }

  .payment-block-row {
    display: flex;
    flex-direction: row;
    align-items: middle;
  }

  .payment-block-label {
    font-size: 16px;
  }

  .payment-block-edit-icon {
    height: 20px;
    width: 20px;
    margin-left: 10px;
  }

  .payment-block-value {
    font-size: 12px;
  }

.payment-block-column input {
    background-color: #e5e3dc;
    border: none;
    border-bottom: 1px solid #8d8d8d;
}

.payment-block-select { 
  border: none;
  border-radius: 0;
  background-color: #e5e3dc;
  border-bottom: 1px solid;
  width: 100%;
}

.payment-block-select > select { 
  -webkit-appearance: none;
  -moz-appearance: none; 
  appearance: none; 
  position: absolute; 
  top: -9999px; 
}
