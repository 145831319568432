.adminManualUsersPageDesktop-wrapper {
    background-color: #e5e3dc;
    padding: 40px 80px;
}

.adminManualUsersPageDesktop-form .react-tel-input .form-control {
    font-family: Comfortaa;
    background-color: #fff;
    width: 100%;
}

.adminManualUsersPageDesktop-form .react-tel-input .selected-flag {
    font-family: Comfortaa;
    background-color: #fff;
}