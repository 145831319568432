.content-zone__lite {
    background-image: url(../../../assets/images/fon-in-login-2.jpg);
    background-position: 50% 50%;
    background-size: cover;
}
.content-zone__lite .side-bar__wrapper {
    display: none;
}
.content-zone__lite .right-zone__wrapper {
    display: flex;
    width: 100%;
}
    .content-zone__lite .right-zone__wrapper .right-zone__content {
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
    }

/* Fon */
.loginPage {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 64px 0;
    background-color: rgba(0, 0, 0, .35);
}
/* Login Form */
.loginModule {
    position: relative;
    z-index: 2;
    width: 480px;
    max-width: 80%;
    padding: 64px 40px 40px 40px;
    background-color: #FFFFFF;
    border-radius: 24px;
    box-shadow: 0 0.625em 1.875em rgba(57, 62, 72, 0.22);
}
    .loginModule__title {
        margin: 0 0 40px 0;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
    }
        .loginModule__title_nomargin {
            margin: 0 0 8px 0;
        }

    .loginModule__text {
        margin: 0 0 24px 0;
    }
        .loginModule__text p {
            margin: 0;
            font-size: 20px;
            line-height: 24px;
            font-weight: 300;
        }
        .loginModule__text_aftersms p {
            font-size: 18px;
            line-height: 24px;
            font-weight: 300;
        }

    .loginModule__confirm {
        margin: 0;
    }
        .loginModule__confirm p {
            margin: 0;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
        }
/* === Header Navigation in Login Form === */
.loginModule__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    top: 12px;
    right: 0;
    z-index: 3;
    width: 100%;
    padding: 0 24px 0 40px;
}
    /* Link and Arrow - Go Home */
    .navigation__gohome {
        font-size: 12px;
        color: #2D4D4C;
        text-decoration: underline;
    }
        .navigation__gohome:hover {
            border-bottom: 1px transparent solid;
            color: #2D4D4C;
        }
        .navigation__arrow {
            display: inline-block;
            width: 12px;
            margin: 0 8px 0 0;
        }

    /* Icon Close */
    .navigation__close {
        position: relative;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
        .navigation__close:after, .navigation__close:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            width: 20px;
            height: 2px;
            background-color: #2D4D4C;
        }
        .navigation__close:after {
            transform: rotate(45deg);
        }
        .navigation__close:before {
            transform: rotate(-45deg);
        }

/* === Enter Email === */
.loginModule__pincode {
    margin: 0 0 24px 0;
}
    .loginModule__input_email input {
        width: 100%;
        height: 52px;
        background-color: unset;
        border: 1px #BABABA solid;
        border-radius: 8px;
        overflow: hidden;
        font-size: 20px;
        line-height: 25px;
        padding: 14px 0 11px 24px;
        letter-spacing: normal;
    }

/* === Pin Code - SMS and Validation Email === */
.pincode-input-text {
    border-radius: 8px;
}

/* Form Elements */
.loginModule__input {
    margin: 0 0 24px 0;
}
    /* Flag in Phone */
    .loginModule__input .react-tel-input {
        width: 100%;
        margin: 0 auto;
    }
        .loginModule__input .react-tel-input .form-control {
            width: 100%;
            height: 52px !important;
            background-color: #E5E3DD;
            border: 1px #BABABA solid;
            border-radius: 8px;
            overflow: hidden;
            font-size: 20px;
            line-height: 25px !important;
            padding: 14px 0 11px 64px;
            letter-spacing: normal !important
        }
        .react-tel-input .flag-dropdown {
            width: 52px;
            height: 52px;
            border: 0;
            border-radius: 8px 0 0 8px !important;
            background: #BABABA;
        }
        .react-tel-input .selected-flag {
            width: 52px;
            margin: 0 auto;
            padding: 0 0 0 12px;
        }
            .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus, .react-tel-input .selected-flag, .react-tel-input .flag-dropdown.open .selected-flag {
                background-color: #a1a0a0;
                border-radius: 8px 0 0 8px;
                color: #2D4D4C;
            }




.ant-modal-content .ant-btn.ant-btn-primary.btn_transparent {
    background-color: transparent;
}
.ant-modal-content .ant-btn.ant-btn-primary.btn_transparent:hover, .ant-modal-content .ant-btn.ant-btn-primary.btn_transparent:focus {
    background-color: #2D4D4C;
    color: #fff;
}
.ant-modal-body .loginModule {
    padding: 0;
}
.ant-modal-body .ant-form.ant-form-horizontal .loginModule__input_minimargin {
    margin: 0 0 16px 0;
}

@media screen and (max-width: 720px) {
    
}