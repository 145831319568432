.calendarPageMobile-wrapper {
    background-color: #e5e3dc;
    width: 100%;
}

.calendarPageMobile-title {
    font-size: 24px;
    padding: 20px;
    text-align: center;
    width: 100%;
    color: #2d4d4c;
    font-weight: bold;
}

.calendarPageMobile-header-wrapper {
    padding: 24px 20px 0 20px;
}

.calendarPageMobile-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}
.calendarPageMobile-row:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #7C7C7C;
}

.calendarPageMobile-body {
    padding: 28px 15px 0 15px;
}
.calendarPageMobile-body .btn_mini-margin {
    margin: 0 0 16px 0;
    padding: 15px 10px;
}
.calendarPageMobile-row-element {
    padding: 0 0 7px 0;
    font-size: 12px;
    font-weight: 400;
    color: #7C7C7C;
    cursor: pointer;
}

/* --- Group List - Group Create --- */
.calendarPageMobile-group {}
.calendarPageMobile-group .btn_green {
    width: 100%;
}
.calendarPageMobile-group-wrapper {
    background-color: #f5f4f1;
    width: 100%;
    border-radius: 10px;
    padding: 20px 0;
    margin: 0 0 24px 0px;
}

.calendarPageMobile-group-wrapper__item {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.calendarPageMobile-group-wrapper__item-arrow {
    width: 48px;
    height: 30px;
    overflow: hidden;
    background-image: url(../../../assets/icons/arrow-long-bottom.svg);
    background-repeat: no-repeat;
    background-size: 10px auto;
    background-position: 50%;
    text-indent: -9999px;
}
.calendarPageMobile-group-wrapper__item-title {
    width: calc(100% - 138px);
    margin: 6px 0 0 0;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    flex-grow: 1;
}

.PageMobile__groupItem__status {
    display: inline-block;
    position: relative;
    width: 90px;
    padding: 8px 10px 6px 20px;
    font-size: 10px;
    text-align: right;
    text-transform: lowercase;
    background-color: #4E6363;
    color: #fff;
}
.PageMobile__groupItem__status span {
    line-height: 1;
}
.PageMobile__groupItem__status:before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-bottom: 15px solid transparent;
    border-top: 15px solid transparent;
    border-left: 10px solid #f5f4f1;
}


.calendarPageMobile-row-element-active {
    position: relative;
    padding: 0 0 7px 0;
    font-size: 12px;
    font-weight: 400;
    color: #2d4d4c;
    cursor: pointer;
}
.calendarPageMobile-row-element-active:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #2d4d4c;;
}

.calendarPageMobile-current-filter {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 0 40px 0;
}

.calendarPageMobile-current-filter-wrapper {
    display: flex;
    flex-direction: row;
}

.calendarPageMobile-current-filter-label {
    background-color: #D0CCC0;
    font-size: 12px;
    color: #2d4d4c;
    border-radius: 5px 0px 0px 5px;
    padding: 5px 20px 5px 10px;
}

.calendarPageMobile-current-select {
    font-size: 12px;
    font-weight: 400;
    background-color: #fff;
    border-radius: 5px;
    margin-left: -10px;
    border: 0;
}

.calendarPageMobile-current-select > select { 
    -webkit-appearance: none;
    -moz-appearance: none; 
    appearance: none; 
    position: absolute; 
    top: -9999px; 
}

.calendarPageMobile-time-filter-button__wrapper, .calendarPageMobile-time-filter-button__wrapper_3 {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.calendarPageMobile-time-filter-button__wrapper_3 {
    margin: 0 0 10px 0;
}
.calendarPageMobile-time-filter-button__wrapper .calendarPageMobile-time-filter-button{
    width: calc(25% - 11.25px);
}
.calendarPageMobile-time-filter-button__wrapper_3 .calendarPageMobile-time-filter-button {
    width: calc(33.3333% - 10px);
}
.calendarPageMobile-time-filter-button {
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #fff;
    color: #2d4d4c;
    margin: 0px 0 15px 0;
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
}
.calendarPageMobile-time-filter-button_green {
    background: #2d4d4c;
    color: #fff;
}
.calendarPageMobile-time-filter-button:hover {
    background-color: #ee836f;
    color: #fff;
}

.calendarPageMobile-time-body-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 0 32px 0;
}
.calendarPageMobile__result-week {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 0 32px 0;
    font-size: 14px;
    font-weight: 700;
}
.calendarPageMobile-time-calendaer_day {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin: 0 0 16px 0;
}
.calendarPageMobile-time-day {
    width: 12%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.calendarPageMobile-time-calendaer_day_time {
    width: 55px;
}
.calendarPageMobile-time-calendaer_day_col {
    width: 12%;
    font-size: 14px;
    text-align: center;
    text-transform: lowercase;
}
.calendarPageMobile-time-day__title {
    width: 55px;
    font-size: 14px;
    line-height: 18px;
}
.calendarPageMobile-time-day input[type="checkbox"] {
    background-color: #E5E3DC;
    border: 1px #808080 solid;
    text-align: center;
}


.calendarPageMobile-time__slots {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 12px 0;
}

.calendarPageMobile-time-day input:disabled {
    background-color: #DC7761;
}
.calendarPageMobile-time-day input:checked {
    background-color: #2d4d4c;
}
.calendarPageMobile-clients-wrapper {
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
    padding: 20px 24px 16px 24px;
    margin: 0 0 24px 0;
}

.calendarPageMobile-clients__block-info {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.calendarPageMobile-clients__open-tab {
    cursor: pointer;
}
.my-clients_mobile__one-block__edit .my-clients__one-block__edit {
    margin: 0 0 16px 0;
}

.PageMobile__groupItem__desc {
    padding: 24px 24px 0 24px;
}
.PageMobile__groupItem__desc__item {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin: 0 0 12px 0;
}
.PageMobile__groupItem__desc__item_last {
    margin: 0 0 24px 0;
}
.calendarPageMobile-group-label {
    font-weight: bold;
    margin-right: 10px;
    font-size: 12px;
    line-height: 1;
}
.calendarPageMobile-group-value {
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
}

.PageMobile__groupItem__process-title {
    margin: 0 0 12px 0;
    font-size: 16px;
    font-weight: 700;
}
.PageMobile__groupItem__process_wrapper {
    margin: 0 0 32px 0;
}
.PageMobile__groupItem__process {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 0 8px 0;
}
.PageMobile__groupItem__process-col {
    font-size: 14px;
    font-weight: 300;
}
.PageMobile__groupItem__process-name {
    font-weight: 400;
}
.PageMobile__groupItem__process-cont {
    text-align: right;
}
.calendarPageMobile-current-result {
    margin: 0 0 32px 0;
}

.calendarPageMobile-current-result__wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 16px 0;
}

.calendarPageMobile-current-result__wrapper .calendarSession-time {
    width: 60px;
    font-size: 12px;
}
.calendarPageMobile-current-result__wrapper .calendarSession__wrapper {
    width: calc(100% - 60px);
    padding: 7px;
    background-color: #fff;
    border-radius: 8px;
}

.calendarSession__block {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.calendarSession__block__param {

}
.calendarSession__block__name {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.calendarSession__block__name .client-nickname {
    margin: 0 8px 0 0;
    font-size: 12px;
    font-weight: 600;
    color: #3C3C3B;
}
.calendarSession__block__name .client-status svg {
    width: 14px;
}
.calendarSession__block__pay {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.calendarSession__block__pay__title {
    font-size: 10px;
    font-weight: 300;
    color: #3C3C3B;
}
.calendarSession__block__pay__value {
    display: inline-block;
    margin: 0 0 0 4px;
    font-size: 10px;
    font-weight: 300;
    color: #3C3C3B;
    text-transform: lowercase;
}
.calendarSession-anketa {
    display: none;
}
.calendarSession__sub-block {
    margin-left: auto;
}