.h3-title {
    margin: 0 0 16px 0;
    font-size: 20px;
    font-weight: 500;
}
.h3-title__topmargin {
    margin: 32px 0 16px 0;
}
.message__no-events {
    margin: 0 0 8px 0;
    font-size: 16px;
    font-weight: 300;
}
.clientSessionsPageDesktop {
    width: 1186px;
    padding: 0px 80px 20px 80px;
}
.clientSessionsPageDesktop__no-padding {
    padding: 0;
}
.client-session__wrapper {
    width: 100%;
}
.client-session__title {
    margin: 0 0 48px 0;
    font-size: 28px;
    font-weight: 700;
    line-height: 1;
}
.client-session__prev {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    margin: 0 0 80px 0;
    padding: 40px 32px;
    border-radius: 8px;
    background-color: #F4F4F1;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 11%);
}
.client-session__prev .client-session__avatar {
    display: block;
    width: 160px;
    height: 160px;
    margin: 0 48px 0 0;
    border-radius: 50%;
}
.client-session__name {
    margin: 0;
    font-size: 28px;
    line-height: 1;
    font-weight: 700;
    color: #2D4D4C;
}
.icon__more-info {
    width: 24px;
    height: auto;
    margin: -24px 0 0 0;
    cursor: pointer;
}
.client-session__count {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    margin: 0 0 32px 0;
    color: #6C8282;
}
.client-session__param {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.client-session__param_1 {
    width: 53px;
    padding: 0 15px 0 0;
}
.client-session__param_2 {
    width: 200px;
    margin: 0 40px 0 0;
}
.client-session__param_2 h3 {
    font-size: 15px;
    line-height: 1;
    font-weight: 700;
}
.client-session__param_2 h4 {
    margin: 0 0 8px 0;
    font-size: 15px;
    line-height: 1;
    font-weight: 900;
    color: #EB715A;
}
.client-session__redline {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: #6C8282;
}
.client-session__param_3 {}

.info-block__link {
    
}

.content-text {
    padding: 0 16px;
}
.content-text__h2  {
    margin: 0 0 16px 0;
    font-size: 30px;
    font-weight: 500;
    line-height: 1;
    color: #333;
}
.content-text__p {
    font-size: 16px;
    line-height: 1.4;
    font-weight: 300;
    color: #333;
}
.content-text__p ~ .content-text__h2 {
    margin: 40px 0 16px 0;
}
.content-text__p .ant-collapse-header {
    color: #D37F67 !important;
}
.clientSessionsPageDesktop .ant-collapse > .ant-collapse-item:last-child, .clientSessionsPageDesktop .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    padding: 0;
    border-radius: 0 !important;
}
.content-text__p .ant-collapse {
    margin: 16px 0 0 0;
}
.content-text__p .ant-collapse-content-box {
    padding: 0;
}




@media screen and (max-width:1450px) {
    .clientSessionsPageDesktop {
        width: 100%;
    }
    .center-1200_mini {
        width: 100%;
    }
}


@media screen and (max-width:1270px) {
    .footerUnd_mini {
        display: block;
    }
    .footerUnd-column {
        width: 100%;
        padding: 0;
    }
    .clientSessionsPageDesktop {
        padding: 80px 32px 40px 32px;
    }
    .client-session__prev .client-session__avatar {
        width: 140px;
        height: 140px;
        margin: 0 24px 0 0;
    }
}


