.profilePageDesktop_center {
    width: 100%;
    padding: 80px 72px 104px 72px;
}
.profilePageDesktop-wrapper {
    width: 100%;
    background-color: #e5e3dc;
    position: relative;
}

.profilePageDesktop-form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.profilePageDesktop-form-column-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.profilePageDesktop-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.profilePageDesktop-form-title {
    font-size: 18px;
    margin-bottom: 20px;
}

.profilePageDesktop-popoverAvatar-uploader {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 40px;
}


.profilePageDesktop-form-column-title {
    margin: 0 0 48px 0;
    font-size: 2em;
    font-weight: 700;
    line-height: 1;
}

.profilePageDesktop-form-column-section-title {
    height: 24px;
    font-size: 18px;
    color: #333333;
    margin-bottom: 15px;
}


@media screen and (max-width:1450px) {
.profilePageDesktop_center {
    padding: 60px 32px 0 40px;
}
}

@media screen and (max-width:1200px) {

}