.registerPsychoPageDesktop-wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.registerPsychoPageDesktop-header-wrapper {
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    align-self: center;
}

.registerPsychoPageDesktop-header-buttons-join {
    margin: 0px 10px;
    font-size: 20px;
    border: none!important;
    background: linear-gradient(90deg, rgba(1,176,73,1) 0%, rgba(39,196,166,1) 50%, rgba(6,189,185,1) 100%)!important;
    -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2)!important;
    -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2)!important;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2)!important;
}

.registerPsychoPageDesktop-header-logo {
    width: 3vw;
    margin-right: 40px;
}

.registerPsychoPageDesktop-header-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}