.profileField-form-column-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
}

.profileField-form-column-block-column {
    display: flex;
    flex-direction: column;
    position: relative;
}

.profileField-form-column-block-row {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
}

.profileField-form-column-block-row-change {
    margin-left: 10px;
    margin-top: 5px;
    color: #1890ff;
    cursor: pointer;
}

.profileField-form-column-block-row .ant-form-item {
    margin-bottom: 0px!important;
}

.profileField-form-column-block-title {
    color: #333333;
    width: 10vw;
    margin-right: 1vw;
}

.profileField-form-column-block-placeholder {
    position: absolute;
    bottom: -24px;
    left: 280px;
    font-weight: 300;
    color: #2D4D4C;
}

.profileField-card {
    margin-top: 10px;
    width: 30vw;
}

.profileField-form-column-block-row .ant-form-item-label {
    text-align: left!important;
}
.profileField-form-column-block-row .ant-form-item-label label {
    width: 280px;
    padding: 0 24px 0 0;
    font-size: 1.25em;
    line-height: 1;
    font-weight: 700;
    color: #2D4D4C;
}
.phone-input-mask-profile {
    border-radius: 5px;
    font-size: 14px;
    padding-bottom: 0!important;
    font-family: Comfortaa;
    width: 100%;
    height: 32px;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 40px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
}

.phone-input-mask-profile:focus-visible {
    border-color: #90c26d;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(113 181 72 / 20%);
}

.profileField-form-column-block-column .ant-form-item-explain.ant-form-item-explain-error {
    display: none;
}
.profileField-form-column-block-column .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before { display: none; }
.profileField-form-column-block .ant-input, .profileField-form-column-block .react-tel-input .form-control {
    background-color: inherit;
    width: 437px;
    padding: 11px 20px 8px 11px;
    font-size: 16px;
    font-weight: 400;
    border: 1px #2D4D4C solid;
    border-radius: 5px;
    color: #2D4D4C;
}
.profileField-form-column-block .react-tel-input .form-control {
    padding: 11px 20px 8px 40px;
}
.react-tel-input > input {
    background-color: inherit;
    width: 437px;
    font-size: 1em;
    font-weight: 400;
    border: 2px #2D4D4C solid;
    border-radius: 5px;
}
.ant-select {
    font-size: 1em;
    font-weight: 400;
}
.profileField-form-column-block .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    width: 437px;
    padding: 8px 20px 6px 11px;
    background-color: inherit;
    border: 1px #2D4D4C solid;
    border-radius: 5px;
    cursor: pointer;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
    top: 8px;
}
.ant-select-single.ant-select-open .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-item {
    color: #2D4D4C;
}

@media screen and (max-width:1270px) {
.profileField-form-column-block .ant-input, .profileField-form-column-block .react-tel-input .form-control, .profileField-form-column-block .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    width: 320px;
}
}