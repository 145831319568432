.profileSpecPageDesktop__title {
    font-family: 'Muller Bold','Nunito Sans', sans-serif;
    margin: 0 0 48px 0;
    font-size: 1.75em;
    font-weight: 700;
    line-height: 1;
}

.profileSpecPageDesktop__prev {
    width: 100%;
    margin: 0 0 48px 0;
}

/* --- TabLink Menu --- */
.profilePageDesktop_center .TabPane__user {
    width: 100%;
}




.profilePageDesktop__prev__wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.profilePageDesktop-avatar-cross {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 100;
    font-size: 2vh;
    cursor: pointer;
    color: #474747!important;
}

.profilePageDesktop-avatar-cross:hover {
    color: #1890ff!important;
}

.profileSpecPageDesktop__prev .profilePageDesktop-avatar {
    width: 140px;
    height: 140px;
    margin: 0 40px 0 0;
    background-color: #2D4D4C;
    border-radius: 8px;
    cursor: pointer; 
    position: relative;
}
.profileSpecPageDesktop__prev .profilePageDesktop-avatar::after {
    content: "";
    display: block;
    position: absolute;
    right: 4px;
    bottom: 4px;
    width: 16px;
    height: 16px;
    background-image: url(./../../../../assets/icons/edit-images.svg);
    background-size: 100% auto;
}
.profilePageDesktop-avatar:hover::after {
    content: 'Изменить';
    display: inline-block;
    position: absolute;
    text-align: center;
    font-size: 1.5vh;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    width: 100px;
    height: auto;
    z-index: 100;
    background: unset;
    margin: -16px 0 0 -50px;
}
.profilePageDesktop-avatar > span {
    font-size: 50px;
    line-height: 140px;
}
.profilePageDesktop-avatar:hover::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 50;
    background-color: #212121;
    width: 100%;
    height: 100%;
    opacity: 0.4;
}


.profilePageDesktop__prev__name {
    margin: 0 0 8px 0;
    font-size: 2em;
    line-height: 1;
}
.profilePageDesktop__approved {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.profilePageDesktop__approved_text {
    margin: 0 0 0 10px;
    font-size: 1.25em;
}

.payment-block {
    width: 50%;
}
.payment-block__left {
    padding: 0 32px 0 0;
}
.payment-block__right {
    padding: 0 0 0 32px;
}
.payment-block__urlico {
    margin: 0 0 8px 0;
    font-size: 16px;
    font-weight: 700;
}
.payment-block__profile-title {
    margin: 0 0 32px 0;
}
.payment-block__line {
    width: 100%;
    margin: 0 0 16px 0;
}
.payment-block__title {
    margin: 0 0 16px 0;
    font-size: 18px;
    font-weight: 700;
}
.payment-block__line ~ .payment-block__title {
    margin: 32px 0 16px 0;
}
.payment-block__line_name {
    width: 180px;
    margin: 0 0 8px 0;
    padding: 0 24px 0 0;
    font-size: 16px;
    font-weight: 600;
}
.payment-block__line_name_long {
    width: 100%;
}

.payment-block__line_input_date {
    order: 2;
}
.payment-block__line_edit {
    order: 1;
    width: 20px;
    margin: 0 8px 0 0;
}
.payment-block__line_edit svg {
    width: 20px;
    height: 20px;
}
.profile-input-block {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 40px 0;
}
.profilePageDesktop-title {
    margin: 0 0 32px 0;
    font-size: 1.75em;
    font-weight: 700;
}
.profilePageDesktop-title_mini {
    margin: 0 0 8px 0;
}
.profilePageDesktop-subtitle {
    margin: -8px 0 32px 0;
    font-size: 18px;
    font-weight: 700;
}
.profileLabel {
    width: 280px;
    padding: 0 24px 0 0;
    font-size: 16px;
    line-height: 1.25;
    font-weight: 600;
}
.profileInput {
    width: 437px;
    padding: 11px 20px 8px 11px;
    font-size: 1em;
    font-weight: 400;
    border: 1px #2D4D4C solid;
    border-radius: 5px;
}
.profileTextArea {
    width: 437px;
    padding: 11px 20px 8px 11px;
    font-size: 1em;
    font-weight: 400;
    border: 1px #2D4D4C solid;
    border-radius: 5px;
}


.profilePageDesktop-phone-input-disabled .react-tel-input .form-control {
    border: none!important;
    width: fit-content!important;
    cursor: initial;
    height: auto;
    padding-left: 20px;
}

.profilePageDesktop-phone-input-disabled .react-tel-input .selected-flag {
    padding: 0;
    background-color: #fff;
    width: fit-content;
}

.profilePageDesktop-phone-input-disabled .react-tel-input .flag-dropdown {
    border: none;
    cursor: initial;
}

.profilePageDesktop-phone-input-disabled .react-tel-input .flag-dropdown:hover {
    background-color: #fff;
}

.profilePageDesktop-phone-input-disabled .react-tel-input .selected-flag .arrow {
    display: none;
}
.payment__link-block {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin: 0 0 16px 0;
}
.payment__link-block button:nth-child(1) {
    margin: 0 8px 0 0;
}
.payment__link-block .btn-default {
    padding: 12px 12px;
}
.payment__help-text p {
    font-size: 12px;
    line-height: 15px;
}

.profile-spec-timeZone .ant-select-selector {
    background-color: #e5e3dd!important;
    border: none!important;
}