.psychoListPageDesktop-wrapper {
    width: 100%;
    padding: 80px 7vw;
    background-color: #e5e3dc;
    min-height: 100%;
}

.psychoListPageDesktop-wrapper  .ant-avatar {
    display: block;
    width: 200px;
    height: 200px;
    margin: 0 auto 30px auto;
    border-radius: 50%;
}

.psychoListPageDesktop-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.specialist-item {
    padding: 32px 24px;
}
.specialist-item__img {
    width: 100px;
    height: 100px;
    margin: 0 auto 16px auto;
}
.specialist-item__name {
    margin: 0 0 8px 0;
    font-size: 18px;
    line-height: 1;
    font-weight: 700;
    text-align: center;
}
.specialist-item__subname {
    margin: 0 0 42px 0;
    text-align: center;
}
.specialist-item__param {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 0 40px 0;
}
    .specialist-item__param_col {
    }
    .specialist-item__param_col img {
        margin: 0 auto 8px auto;
        height: 32px;
    }
    .specialist-item__param_col h3 {
        margin: 0 0 8px 0;
        font-size: 14px;
        line-height: 1;
        font-weight: 700;
    }
    .specialist-item__sub {
        font-size: 14px;
        line-height: 1;
    }
.psycho-list .psychoListPageDesktop-filter {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 0 72px 0;
}
.psychoListPageDesktop-filter-wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 40px 0;
}
.psychoListPageDesktop-filter-wrapper_margin-bottom {
    margin: 0 0 20px 0;
}
/* Filters */
.psychoListPageDesktop-filter-wrapper .ant-select-selection-overflow {
    width: 430px;
}
.psychoListPageDesktop-filter-wrapper .ant-select {
    position: relative;
    border: 1px #999999 solid;
    border-radius: 8px;
}
.psychoListPageDesktop-filter-wrapper .ant-select:before {
    content: "";
    display: block;
    position: absolute;
    right: 8px;
    top: 50%;
    width: 10px;
    height: 5px;
    margin: -3px 0 0 0;
    background-image: url(../../../assets/icons/icon-arrow-bottom.svg);
}
.psychoListPageDesktop-filter-wrapper .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    background-color: unset;
    border-radius: 8px;
    cursor: pointer;
}
.psychoListPageDesktop-filter-wrapper .ant-select-multiple .ant-select-selection-item {
    background-color: unset;
    border: 0;
}
.psychoListPageDesktop-filter-wrapper .ant-select-multiple .ant-select-selection-item-content {
    line-height: 24px;
    font-weight: 300;
}
.psychoListPageDesktop-filter-wrapper .ant-select-multiple .ant-select-selection-item-remove > .anticon {
    vertical-align: -3.5px;
}
.psychoListPageDesktop-filter__lang {
    width: 276px;
    padding: 0 32px 0 0;
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
}
.psychoListPageDesktop-filter-wrapper .ant-slider {
    width: 430px;
}
.specialist-item__btn-row {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.specialist-item__btn-row .btn_common {
    width: calc(50% - 0.8vw);
}

.psychoListPageDesktop-loading {
    position: absolute;
    z-index: 1000;
    top: 45%;
    left: 45%;
}