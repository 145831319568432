@import 'swiper/swiper.min.css';
.homePageDesktop-wrapper {
    width: 100%;
}
.homePageDesktop-h2 {
    font-size: 2.375em;
    font-weight: 700;
    text-align: center;
}
/* --- Header --- */
.homePageDesktop-header-wrapper {
    width: 100%;
    padding: 32px 80px 24px 80px;
    background-color: #edebe7;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.11);
}
.header-flex {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.header-logo {
    width: 164px;
    margin: 0 0 0 -29px;
}
.homePageDesktop-header-wrapper-second {
    margin: 0 0 0 144px;
    flex-grow: 1;
}
.homePageDesktop-header-wrapper-second a {
    display: inline-block;
    margin: 0 32px 0 0;
    font-size: 1em;
    color: #2D4D4C;
    font-weight: 600;
}
.homePageDesktop-header-wrapper-second a:hover {
    color: #1d3a39;
}
.button-enter {
    display: inline-block;
}
.header-buttons > button:nth-child(1) {
    margin: 0 16px 0 0;
}
.header-buttons > button:nth-child(2) {
    margin: 0 32px 0 0;
}
/* --- END - Header --- */

/* --- First Screen --- */
.homePageDesktop-homeFirstScreen {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 72px 0;
    border-bottom: 1px #2D4D4C solid;
}
.homePageDesktop-homeBigImage-wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
}
.homePageDesktop-homeBigImage-text {
    width: 150px;
    font-size: 0.625em;
    font-weight: 600;
    line-height: 14px;
    text-align: right;
    margin: 0 12px 0 0;
}
.homePageDesktop-homeBigImage {
    width: 474px;
}

.mp__h1 {
    margin: 0 0 32px 0;
    font-family: "Cormorant",serif;
    font-size: 4.625em;
    line-height: 1;
    font-weight: 600;
}
.mp__h1_sub {
    margin: 0 0 24px 0;
    font-family: "Cormorant",serif;
    font-size: 3.125em;
    line-height: 1;
    font-weight: 600;
}
.mp__h1_text {
    margin: 0 0 48px 0;
    font-family: 'NunitoSans';
    font-size: 1.25em;
    line-height: 1;
}
/* --- END - First Screen --- */
/* --- Second Screen --- */
.homePageDesktop-kindWrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.homePageDesktop-kindWrapper-col {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 50%;
}
.homePageDesktop-kindWrapper-col img {
    margin: 0 0 40px 0;
}
.homePageDesktop-kindWrapper-1 {
    padding: 64px 80px 64px 0;
    border-right: 1px #2D4D4C solid;
}
.homePageDesktop-kindWrapper-2 {
    padding: 64px 0 64px 80px;
}
.homePageDesktop-kindWrapper-title {
    margin: 0 0 32px 0;
    font-size: 2.1em;
    font-weight: 700;
}
.homePageDesktop-kindWrapper-text {
    font-size: 1.375em;
    line-height: 30px;
    flex-grow: 1;
}
/* --- END - Second Screen --- */
/* --- Gestalt Block --- */
.homePageDesktop-gestalt {
    position: relative;
    margin: 0 auto 128px auto !important;
    padding: 80px 160px;
    border-radius: 16px;
    background-color: #2D4D4C;
    text-align: center;
}
.homePageDesktop-gestalt p {
    font-family: "Cormorant",serif;
    font-size: 1.75em;
    line-height: 40px;
    color: #E5E3DD;
}
.homePageDesktop-gestalt h2 {
    margin: 0 0 40px 0;
    font-size: 2.5em;
    font-weight: 700;
    color: #E5E3DD;
}
.homePageDesktop-gestalt-btn {
    position: absolute;
    left: 50%; bottom: 0;
    -webkit-transform: translate(-50%,50%);
    -o-transform: translate(-50%,50%);
    -moz-transform: translate(-50%,50%);
    transform: translate(-50%,50%);
}
/* --- END - Gestalt Block --- */
/* --- Argument Block --- */
.homePageDesktop-help {
    position: relative;
    z-index: 5;
    padding: 64px 0 74px 0;
    background-color: #F5F4F1;
    border-radius: 32px;
}
.homePageDesktop-help-wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
}
.homePageDesktop-3-col {
    width: 26%;
    margin: 0 0 72px 0;
    padding: 0 24px 0 0;
}
.homePageDesktop-cirlce-number {
    width: 34px;
    height: 34px;
    margin: 0 0 32px 0;
    border-radius: 50%;
    background: #2D4D4C;
    font-size: 1em;
    font-weight: 700;
    line-height: 34px;
    text-align: center;
    color: #fff;
}
.homePageDesktop-help-text {
    font-size: 1.375em;
    line-height: 30px;
}
.homePageDesktop-help-btn {
    position: absolute;
    z-index: 5;
    left: 50%; bottom: 0;
    -webkit-transform: translate(-50%,50%);
    -o-transform: translate(-50%,50%);
    -moz-transform: translate(-50%,50%);
    transform: translate(-50%,50%);
}
/* --- END - Argument Block --- */
/* --- Selection Block --- */
.homePageDesktop-selection {
    position: relative;
    z-index: 4;
    margin: -50px 0 0 0;
    padding: 170px 0 152px 0;
    background-color: #C8D1D1;
    border-radius: 0 0 32px 32px ;
}
.homePageDesktop-selection-wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.homePageDesktop-4-col {
    width: 240px;
    text-align: left;
}
.homePageDesktop-selection-btn {
    position: absolute;
    left: 50%; bottom: 0;
    -webkit-transform: translate(-50%,50%);
    -o-transform: translate(-50%,50%);
    -moz-transform: translate(-50%,50%);
    transform: translate(-50%,50%);
}
.homePageDesktop-selection-prev {
    margin: 0 0 72px 0;
    text-align: center;
}
.homePageDesktop-selection-prev p {
    font-size: 1.375em;
    line-height: 30px;
}
/* --- END - Selection Block --- */
/* --- Reviews Block --- */
.swiper-container {
    width: 900px;
    margin: 0 auto;
}
.homePageDesktop-reviews {
    position: relative;
    z-index: 3;
    margin: -50px 0 0 0;
    padding: 170px 0 160px 0;
    background-color: #2D4D4C;
    border-radius: 0 0 32px 32px;
}
.homePageDesktop-reviews__slider {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.homePageDesktop-reviews__foto {
    width: 180px;
    height: auto;
}
.homePageDesktop-reviews__content {
    width: calc(100% - 180px);
    padding: 0 0 0 64px;
}
.homePageDesktop-reviews__title {
    margin: 0 0 48px 400px;
    font-size: 2.75em;
    font-weight: 700;
    color: #E5E3DD;
}
.homePageDesktop-reviews__text, .homePageDesktop-reviews__name {
    margin: 0 0 40px 0;
    font-size: 20px;
    line-height: 32px;
    color: #e5e3dd;
}
/* --- END - Reviews Block --- */
/* --- Read Blog --- */
.homePageDesktop-blog {
    position: relative;
    z-index: 2;
    padding: 154px 0 120px 0;
    margin: -50px 0 0 0;
    background-color: #C8D1D1;
    border-radius: 0 0 32px 32px;
}
.homePageDesktop-blog__title {
    text-align: center;
}
.h4-undTitle {
    margin: 0 0 8px 0;
    font-size: 2.125em;
    line-height: 1;
}
.homePageDesktop-blog-wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.blog-item {
    position: relative;
    width: calc(25% - 30px);
    padding: 16px;
    background-color: #F4F4F1;
    color: #333;
    border-radius: 4px;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow:    4px 4px 8px 0px rgba(0, 0, 0, 0.1);
    box-shadow:         4px 4px 8px 0px rgba(0, 0, 0, 0.1);
}
.blog-param h3 {
    margin: 0 0 32px 0;
    font-size: 1.25em;
    font-weight: 700;
    line-height: 26px;
}
.blog-param a {
    display: block;
    margin: 0 0 200px 0;
    font-size: 1.25em;
    color: #333;
}
.blog-param p {
    font-size: 0.875em;
    font-weight: 700;
}
.blog-item__readmore {
    position: absolute;
    right: 16px;
    top: 24px;
    width: 20px;
}
/* --- END - Read Block --- */
/* --- Psy Choose Block --- */
.homePageDesktop-choosePsy-wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.choosePsy-item__param {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.homePageDesktop-choosePsy__img {
    width: 120px;
    margin: 0 0 32px 0;
    border-radius: 50%;
}
.homePageDesktop-subText {
    width: 780px;
    margin: 0 auto 64px auto;
    font-size: 1.375em;
    line-height: 30px;
    text-align: center;
}
.homePageDesktop-choosePsy-wrapper {
    border-top: 2px #2D4D4C solid;
}
.choosePsy-item {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 33.3333%;
    padding: 32px 0 120px 0;
}
.choosePsy-item_1 {
    padding: 32px 48px 120px 0;
}
.choosePsy-item_2 {
    padding: 32px 48px 120px 48px;
    border-left: 2px #2D4D4C solid;
    border-right: 2px #2D4D4C solid;
}
.choosePsy-item_3 {
    padding: 32px 0 120px 48px;
}
.choosePsy-item__param, .choosePsy-item__btn-list {
    width: 300px;
}
.choosePsy-item__btn-list {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.choosePsy-item__btn-list button:nth-child(1) {
    margin: 0 8px 0 0;
}
.choosePsy-item__h3 {
    margin: 0 0 16px 0;
    font-size: 1.875em;
    font-weight: 700;
    line-height: 1;
}
.choosePsy-item__prof {
    width: 240px;
    margin: 0 0 24px 0;
    font-size: 1em;
    line-height: 20px;
    flex-grow: 1;
}
.choosePsy-item__param_col {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 33.3333%;
}
.homePageDesktop-choosePsy-item__param__icon {
    width: auto;
    height: 32px;
}
.choosePsy-item__param_col h4 {
    margin: 0 0 8px 0;
    font-size: 1em;
    font-weight: 700;
}
.choosePsy-item__param_col p {
    font-size: 1em;
}


@media screen and (max-width:1270px) {
    .homePageDesktop-header-wrapper-second {
        margin: 0 0 0 80px;
    }
}