.groupSessions-wrapper .ant-collapse-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.groupSessions-panel-title {
    color: '#212121';
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
}

.groupSessions-wrapper > .btn-default {
    margin: 0 0 32px 0;
}


.modal-form__createGroup {
    text-align: left;
}

.profilePageDesktop_center .groupItem {
    position: relative;
    margin: 0 0 16px 0;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 11%);
}
.profilePageDesktop_center .groupItem.old-group {
    opacity: 0.4;
}
.groupItem::before {
    content: "";
    display: block;
    position: absolute;
    left: 26px;
    top: 34px;
    width: 16px;
    height: 20px;
    background-image: url(../../../../assets/icons/arrow-long-bottom.svg);
    background-position: 50%;
    background-repeat: no-repeat;
}
.groupItem__title h3 {
    margin: 0 0 16px 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
}
.groupItem__line {
    margin: 0;
    font-size: 16px;
    font-weight: 300;
    color: #2D4D4C;
}
.groupItem__line strong {
    font-weight: 700;
}
.ant-collapse.groupItem > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding: 24px 0 24px 64px;
}

.groupItem__prev {
    position: relative;
    width: 100%;
}

/* --- Block with Action Flag --- */
.groupItem__action {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
}
.groupItem__btn {
    height: 100%;
}
.groupItem__icon-edit {
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 16px 0 0;
    background-image: url(../../../../assets/icons/edit.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 20px auto;
}
.groupItem__icon-edit:hover {
    background-image: url(../../../../assets/icons/edit.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 20px auto;
}
.groupItem__icon-edit span {
    display: none;
}

/* --- Start Group --- */
.groupItem__icon-play {
    display: inline-block;
    margin: 0 16px 0 0;
}
.groupItem__icon-play:hover {
}
/* --- Status Flag --- */
.groupItem .groupItem__status .ant-tag {
    display: block;
    width: 100%;
    padding: 8px 12px 6px 12px;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    border-radius: 0;
    font-weight: 300;
}
.groupItem .groupItem__status .ant-tag-gold, .groupItem .groupItem__status .ant-tag-red {
    background-color: #EE836F;
    color: #fff;
    border: 1px #EE836F solid;
}
.groupItem .groupItem__status .ant-tag-green {
    background-color: #2D4D4C;
    color: #fff;
    border: 1px #2D4D4C solid;
}
/* --- END - Block with Action Flag --- */

.groupItem .ant-collapse-item .ant-collapse-content {
    padding: 24px 24px 24px 64px;
    border: 0;
}
.groupItem .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}
.groupItem__desc {
    margin: 0 0 32px 0;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #2D4D4C;
}
.groupItem__title-count {
    margin: 0 0 8px 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #2D4D4C;
}

.modal-form__createGroup .ant-alert-with-description.ant-alert-no-icon {
    margin: 0 0 16px 0;
}

.groupItem__expanded .ant-table-wrapper {
    margin: 0 0 32px 0;
}