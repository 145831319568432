.profileClient-wrapper {
    background-color: #2d4d4c;
    border-radius: 16px 16px 0px 0px;
    color: #ffffff;
}

.profileClient-header-row {
    padding: 32px 16px 40px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profileClient-header-logo {
    background: #8d8d8d;
    width: 65px;
    height: 65px;
    border-radius: 50%;
}

.profileClient-header-name {
    width: calc(100% - 85px);
    padding: 0 40px 0 0;
    font-size: 20px;
    line-height: 1.25;
    margin-left: 20px;
}

.profileClient-body-wrapper {
    background-color: #e5e3dc;
    border-radius: 16px 16px 0px 0px;
    height: 100%;
    color: #2d4d4c;
    padding: 32px 16px 0 16px;
    display: flex;
    flex-direction: column;
}

.profileClient-body-row-label {
    font-size: 14px;
    font-weight: 300;
    line-height: 1;
}
.profileClient-body-row {
    width: 100%;
    margin: 0 0 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.profileClient-body-row__padding .btn-default {
    padding: 12px 14px;
}
.profileClient-body-row-value {
    font-size: 14px;
    font-weight: 700;
    text-align: right;
    text-overflow: ellipsis;
    max-width: 40vw;
    overflow: hidden;
    white-space: nowrap;
}

.profileClient-body-button-primary {
    color: #ffffff;
    background-color: #EE836F;
    padding: 0px 10px;
    width: 42vw;
    border-radius: 10px;
    font-weight: bold;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profileClient-body-button-default {
    color: #2d4d4c;
    background-color: #ffffff;
    padding: 0px 10px;
    border-radius: 10px;
    font-weight: bold;
    word-wrap: break-word;
    width: 42vw;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.2;
}

.profileClient-body-row-value-wrapper {
    display: flex;
    flex-direction: row;
}

.profileClient-header-edit {
    height: 20px;
    width: 20px;
    margin-left: 10px;
}

.profileClient-select { 
    border: none;
    width: 50vw;
    border-radius: 0;
    background-color: #e5e3dc;
    border-bottom: 1px solid;
}

.profileClient-select > select { 
    -webkit-appearance: none;
    -moz-appearance: none; 
    appearance: none; 
    position: absolute; 
    top: -9999px; 
}
