.header-wrapper {
    position: relative;
    z-index: 10;
    width: 100%;
    padding: 32px 80px 24px 80px;
    background-color: #edebe7;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.11);
}
.wrapper_horizontal_justify {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.logo {
    width: 12vw;
}
.header-logo {
    width: 100%;
    cursor: pointer;
}
.top-menu {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.top-menu__link {
    cursor: pointer;
}
.ant-dropdown-trigger {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 600;
}
.ant-dropdown-trigger img {
    width: 12px;
    margin: 0 0 0 8px;
}
.top-menu__avatar {
    width: 44px;
    height: 44px;
    margin: 0 24px 0 80px;
}
.top-menu__avatar > span {
    background-color: #2D4D4C;
}
.ant-dropdown-trigger span {
    font-size: 16px;
    white-space: nowrap;
}
.top-menu__avatar span {
    display: block;
    width: 100%;
    height: 100%;
}
.top-menu__avatar span svg {
    width: 60%;
    height: 60%;
    margin-top: 17%;
}

@media screen and (max-width:1270px) {
.header-wrapper {
    padding: 32px 30px 24px 80px;
}
}