.psychoSelectPageDesktop {
}
.block-btn-back {
    margin: 0 0 16px 0;
}
.psychoSelectPageDesktop__prev {
    width: 100%;
    max-width: 1024px;
    margin: 0 0 40px 0;
    padding: 40px 32px;
    background-color: #f4f4f1;
    -webkit-box-shadow: 2px 2px 7px 0px rgb(50 50 50 / 13%);
    -moz-box-shadow: 2px 2px 7px 0px rgba(50, 50, 50, 0.13);
    box-shadow: 2px 2px 7px 0px rgb(50 50 50 / 13%);
    border-radius: 5px;
}
.psychoSelectPageDesktop .ant-tag-checkable {
    margin: 0 12px 12px 0;
    padding: 8px 12px 6px 12px;
    border: 1px solid rgba(45,77,76,0.56);
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
}
.psychoSelectPageDesktop .ant-tag-checkable-checked {
    background-color: #EB715A;
    border: 1px #EB715A solid;
}
.psychoSelectPageDesktop .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
}

.psychoSelectPageDesktop .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}

.psychoSelectPageDesktop .ant-anchor-ink {
    display: none;
}

.psychoSelectPageDesktop .ant-anchor-link {
    padding: 0;
}
.psychoSelectPageDesktop__wrapper {
    width: 100%;
}
.active-psycho__param-1 {
    flex-grow: 1;
}
.body-wrapper .active-psycho__avatar {
    display: block;
    width: 145px;
    height: 145px;
    margin: 0 32px 0 0;
    border-radius: 50%;
}
.body-wrapper .active-psycho__prev {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin: 0 0 16px 0;
}
.active-psycho__name {
    margin: 0 0 8px 0;
    font-size: 28px;
    font-weight: 700;
    color: #2D4D4C;
}
.active-psycho__prev-left {
    width: 36px;
    margin: 0 16px 0 0;
}
.active-psycho__icon-calendar {
    margin: 2px 0 0 0;
}
.active-psycho__title-time {
    margin: 0 0 8px 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    color: #2D4D4C;
}
.active-psycho__date {
    margin: 0 0 8px 0;
    font-size: 16px;
    font-weight: 800;
    line-height: 1;
    color: #EB715A;
}
.active-psycho__param-2 {
    width: 360px;
}
.active-psycho__param__line {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin: 0 0 16px 0;
}
.active-psycho__param__price {
    margin: 0 0 24px 0;
    font-size: 28px;
    font-weight: 700;
    color: #2D4D4C;
}
.active-psycho__param__line-title {
    width: 150px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    color: #2D4D4C;
}
.active-psycho__param__line-text {
    width: calc(100% - 150px);
    font-size: 18px;
    line-height: 1;
    font-weight: 300;
    color: #2D4D4C;
}
.time-to-go {
    margin: 32px 0 0 0;
}