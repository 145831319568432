.side-bar__wrapper {
    width: 280px;
}
.side-bar__col {
    height: 100%;
    padding: 64px 0;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    background-color: #2d4d4c;
}

@media screen and (max-width: 1200px) {
.side-bar__col {
    width: 200px;
}
}