.sideBarButton-wrapper {
    padding: 24px 0;
    text-align: center;
    border-left: 4px #2d4d4c solid;
    border-right: 4px #2d4d4c solid;
}
.sideBarButton-wrapper:hover, .sideBarButton-wrapper.active {
    background-color: #123131;
    border-left: 4px #EE836F solid;
}
.sideBarButton__img {
    position: relative;
}
.sideBarButton__icon {
    width: 40px;
    height: 40px;
    margin: 0 auto 8px auto;
    background-size: 100% auto;
    background-position: 50%;
    background-repeat: no-repeat;
    color: #4c4c4c;
}

.sideBarButton__icon_profile {
    background-image: url(../../../../assets/icons/menu/profile.svg);
}
.sideBarButton-wrapper:hover .sideBarButton__icon_profile, .sideBarButton-wrapper.active .sideBarButton__icon_profile {
    background-image: url(../../../../assets/icons/menu/profile-active.svg);
}
.sideBarButton__icon_calendar {
    background-image: url(../../../../assets/icons/menu/calendar.svg);
}
.sideBarButton-wrapper:hover .sideBarButton__icon_calendar, .sideBarButton-wrapper.active .sideBarButton__icon_calendar {
    background-image: url(../../../../assets/icons/menu/calendar-active.svg);
}
.sideBarButton__icon_help {
    background-image: url(../../../../assets/icons/menu/help.svg);
}
.sideBarButton-wrapper:hover .sideBarButton__icon_help, .sideBarButton-wrapper.active .sideBarButton__icon_help {
    background-image: url(../../../../assets/icons/menu/help-active.svg);
}
.sideBarButton__icon_specialists {
    height: 44px;
    background-image: url(../../../../assets/icons/menu/catalog.svg);
}
.sideBarButton-wrapper:hover .sideBarButton__icon_specialists, .sideBarButton-wrapper.active .sideBarButton__icon_specialists {
    background-image: url(../../../../assets/icons/menu/catalog-active.svg);
}
.sideBarButton__icon_meetings {
    background-image: url(../../../../assets/icons/menu/individual.svg);
}
.sideBarButton-wrapper:hover .sideBarButton__icon_meetings, .sideBarButton-wrapper.active .sideBarButton__icon_meetings {
    background-image: url(../../../../assets/icons/menu/individual-active.svg);
}
.sideBarButton__icon_group {
    background-image: url(../../../../assets/icons/menu/group.svg);
}
.sideBarButton-wrapper:hover .sideBarButton__icon_group, .sideBarButton-wrapper.active .sideBarButton__icon_group {
    background-image: url(../../../../assets/icons/menu/group-active.svg);
}
.sideBarButton__text {
    text-align: center;
    font-size: 1em;
    line-height: 1.2;
    font-weight: 600;
    text-transform: uppercase;
    color: #9CABAB;
}
.sideBarButton-wrapper:hover .sideBarButton__text, .sideBarButton-wrapper.active .sideBarButton__text {
    color: #fff;
}


@media screen and (max-width: 1200px) {
.sideBarButton__text {
    font-size: 14px;
}
}