.clientGroupSessionsPageDesktop-wrapper {
    width: 100%;
    padding: 80px 72px 104px 72px;
    background-color: #E5E3DC;
}
.wrapper__clientsession .group-session__list .ant-list-items {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.wrapper__clientsession .group-session__list .group-session__item {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: calc(33.3333% - 40px);
    margin: 0 40px 40px 0;
    padding: 24px;
    border-radius: 8px;
    background-color: #f4f4f1;
}
.wrapper__clientsession .group-session__item .ant-list-item-meta-content {
    display: block;
    width: 100%;
}
.wrapper__clientsession .group-session__item .ant-list-item-meta-title {
    display: block;
    width: 100%;
    margin: 0;
    line-height: 1;
}
.wrapper__clientsession .group-session__item .ant-list-item-meta {
    display: block;
    flex: unset;
    width: 100%;
}
.wrapper__clientsession .group-session__item .ant-list-item-meta-avatar {
    width: 100%;
    margin: 0;
}
.wrapper__clientsession .group-session__item__header {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 20px 0;
}
.wrapper__clientsession .group-session__item__header .ant-avatar-image {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
.wrapper__clientsession .group-session__item__header .ant-avatar-image img {
    width: 100%;
    height: 100%;
}

.wrapper__clientsession .group-session__item__trainer {
    flex-grow: 1;
    padding: 0 32px 0 16px;
}
.wrapper__clientsession .group-session__item__trainer-name {
    margin: 0;
    font-size: 17px;
    line-height: 18px;
    font-weight: 700;
    color: #2D4D4C;
}
.wrapper__clientsession .group-session__item__trainer-name span {
    display: block;
}
.wrapper__clientsession .group-session__item__trainer-title {
    font-size: 11px;
    line-height: 20px;
    font-weight: 300;
    color: #2D4D4C;
}

.wrapper__clientsession .group-session__item__date {
    text-align: right;
}
.wrapper__clientsession .group-session__item__day {
    margin: 0 0 10px 0;
    font-size: 20px;
    line-height: 1;
    font-weight: 700;
    color: #2D4D4C;
}
.wrapper__clientsession .group-session__item__hours {
    display: inline-block;
    padding: 8px 11px 7px 11px;
    background-color: #2D4D4C;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    color: #fff;
    border-radius: 16px;
}

.wrapper__clientsession .group-session__item__description {
    width: 100%;
}
.wrapper__clientsession .group-session__item__title {
    margin: 0 0 10px 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #2D4D4C;
}
.wrapper__clientsession .group-session__item__text {
    margin: 0 0 24px 0;
    padding: 0 0 20px 0;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    color: #2D4D4C;
    border-bottom: 1px #2D4D4C solid;
}
.wrapper__clientsession .group-session__item__param {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 0 48px 0;
}
.wrapper__clientsession .group-session__item__param_col {
    font-size: 14px;
    color: #2D4D4C;
}
.wrapper__clientsession .group-session__item__param_col span {
    font-weight: 300;
}
.wrapper__clientsession .group-session__item__param_col p {
    font-weight: 700;
}

.wrapper__clientsession .group-session__item__footer {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
}
.wrapper__clientsession .group-session__item .ant-list-item-action {
    width: 100%;
    margin: 0;
    padding: 0;
}
.wrapper__clientsession .group-session__item .ant-list-item-action li {
    width: 100%;
    padding: 0;
}
.wrapper__clientsession .group-session__item__foot-left {
    text-align: left;
}
.wrapper__clientsession .group-session__item__count-title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: #2D4D4C;
}
.wrapper__clientsession .group-session__item__count {
    font-size: 24px;
    line-height: 1;
    font-weight: 700;
    color: #2D4D4C;
}

.wrapper__clientsession .group-session__tabpane-my-group sup {
    right: -10px;
}
.wrapper__clientsession .group-session__tabpane-my-group .ant-badge-count {
    border: 0;
    background-color: #EB715A;
    box-shadow: unset;
    font-weight: 300;
    line-height: 16px;
}
.wrapper__clientsession .group-session__tabpane-my-group .ant-badge-count p {
    box-shadow: unset;
    font-size: 10px;
}

.wrapper__clientsession .group-session__one-block {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    position: relative;
    max-width: 800px;
    margin: 0 0 32px 0;
    padding: 32px 24px;
    background-color: #f4f4f1;
    border-radius: 16px;
}
.wrapper__clientsession .group-session__one-block .ant-list-item-action {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin: 0;
}
.wrapper__clientsession .group-session__one-block .ant-list-item-action li {
    padding: 0;
    margin: 0 0 0 16px;
}
.wrapper__clientsession .group-session__one-block .ant-list-item-action li .ant-list-item-action-split {
    display: none;
}
.wrapper__clientsession .client__group-session__param {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 0 16px 0;
}
.wrapper-100per {
    width: 100%;
}
.wrapper__clientsession .go-to-meet {
}
.wrapper__clientsession .client__group-session__title {
    margin: 0 80px 0 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
}
.client__group-session__person {
    margin: -16px 0 24px 0;
    font-size: 16px;
    font-weight: 400;
}
.wrapper__clientsession .client__group-session__date {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
}
.client__group-session__desc {
    padding: 0 200px 0 0;
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
    color: #2D4D4C;
}
.wrapper__clientsession .client__group-session__left .ant-avatar-square {
    border-radius: 50%;
}
.client__group-session__status {
    margin: -7px 0 0 0;
}
.wrapper__clientsession .client__group-session__status .ant-tag-blue {
    position: relative;
    margin: 0;
    padding: 9px 16px 6px 16px;
    background-color: #486464;
    color: #fff;
    border: 0;
    border-radius: 0;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
}
.wrapper__clientsession .client__group-session__status .ant-tag-gold {
    position: relative;
    margin: 0;
    padding: 8px 16px 8px 16px;
    background-color: #EB715A;
    color: #fff;
    border: 0;
    border-radius: 0;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 0.1em;
}
.client__group-session__avatar {
    width: 80px;
    height: 80px;
}
.client__group-session__avatar img {
    width: 100%;
}

@media screen and (max-width:1860px) {
    .wrapper__clientsession .group-session__item__header .ant-avatar-image {
        margin: 0 0 24px 0
    }
    .wrapper__clientsession .group-session__item__date {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .wrapper__clientsession .group-session__item__day {
        margin: 0 16px 0 0;
    }
    .wrapper__clientsession .group-session__item__hours {
        margin: -3px 0 0 0;
    }
    .wrapper__clientsession .group-session__item__trainer {
        padding: 0 0 0 16px;
    }
    .wrapper__clientsession .group-session__item__count-title {
        font-size: 12px;
    }
    .wrapper__clientsession .group-session__item__count {
        font-size: 20px;
    }
    .group-session__item__footer .btn-default {
        padding: 12px 12px;
        font-size: 12px;
    }
    .wrapper__clientsession .group-session__item__foot-left {
        width: 90px;
        margin: 0 16px 0 0;
    }

}

@media screen and (max-width:1500px) {
    .wrapper__clientsession .group-session__list .group-session__item:nth-child(3n) {
        margin: 0 40px 40px 0;
    }
    .wrapper__clientsession .group-session__list .group-session__item:nth-child(2n) {
        margin: 0 0 40px 0;
    }
    .wrapper__clientsession .group-session__item__date {
        width: 100%;
    }
    .wrapper__clientsession .group-session__list .group-session__item {
        width: calc(50% - 45px);
    }
}