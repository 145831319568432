.profilePageDesktop_center .calendarPageDesktop {
    padding: 104px 72px;
    background-color: #e5e3dc;
}
/* --- Calendar\menu --- */
.profilePageDesktop_center .calendarPageDesktop-month {
    padding: 14px 0px;
    border-radius: 8px 8px 0 0;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    line-height: 1;
}

.calendarPageDesktop-current__search-day {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 0 48px 0;
    padding: 0 8px;
    background-color: #B5C1C0;
}
.profilePageDesktop_center .calendarPageDesktop-week {
    padding: 16px 6px 14px 6px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    font-weight: 400;
    line-height: 1;
    border-radius: 0;
}
.profilePageDesktop_center .calendarPageDesktop-week_active, .profilePageDesktop_center .calendarPageDesktop-week:hover {
    background-color: #92A2A2;
    color: #2D4D4C;
}
.profilePageDesktop_center #rc-tabs-2-panel-2 .calendarPageDesktop-week {
    border-radius: 8px 8px 0 0;
}
.profilePageDesktop_center .calendarPageDesktop-day {
    padding: 10px 0px;
    text-align: center;
}
.profilePageDesktop_center .calendarPageDesktop-day > div {
    font-size: 16px;
    font-weight: 700;
    color: #2D4D4C;
}
/* END */

/* --- Title Day --- */
.calendarPageDesktop-current__day {
    font-size: 22px;
    font-weight: 700;
}
.calendarPageDesktop-current__day span {
    display: inline-block;
    margin: 0 0 0 12px;
    font-size: 16px;
    font-weight: 300;
}
/* --- Result --- */
.calendarPageDesktop-current-result {
    width: 100%;
}
.calendarPageDesktop-current-result__wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 16px 0;
}
/* --- Result - Right Zone --- */
.profilePageDesktop_center .calendarSession__wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: calc(100% - 80px);
    padding: 16px 32px;
    background-color: #fff;
    border: 1px #d6d5d3 solid;
    border-radius: 8px;
}
.calendarPageDesktop-current-result .calendarSession-time {
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: flex;
    width: 80px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
}

/* --- Result - left (Count, Name, Status) --- */
.PageDesktop__calendarSession__block {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.PageDesktop__calendarSession__block__param {

}
.calendarSession__param .calendarSession-count {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    text-align: center;
    width: 66px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    color: #2D4D4C;
}
.PageDesktop__calendarSession__block__name {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.PageDesktop__calendarSession__block__name .client-nickname {
    font-size: 14px;
    font-weight: 600;
    color: #2D4D4C;
    margin: 0 10px 0 0;
}
.PageDesktop__calendarSession__block__name .client-status svg {
    width: 16px;
    height: 16px;
}

/* --- Pay Status --- */
.PageDesktop__calendarSession__block__pay {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 14px;
    font-weight: 300;
    color: #2D4D4C;
}
.PageDesktop__calendarSession__block__pay__value {
    margin: 0 0 0 5px;
    text-transform: lowercase;
}


.profilePageDesktop_center .calendarSession__sub-block {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%;
    text-align: right;
}

.calendarSession__action {
    margin-left: auto;
}
.profilePageDesktop_center .calendarSession-anketa {
    font-size: 1em;
    font-weight: 700;
    cursor: pointer;
}
.profilePageDesktop_center .start-session {
    margin: 0 0 0 24px;
}
.profilePageDesktop_center .calendarPageDesktop .ant-checkbox-inner {
    background-color: #e5e3dc;
    border-color: #afaeaa;
}

.profilePageDesktop_center .calendarPageDesktop .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: inherit;
    border-color: #bfbdbb;
}
.calendarPageDesktop-day .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2D4D4C;
    border-color: #2D4D4C;
}
.calendarPageDesktop-day .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #E79F8B;
    border-color: #E79F8B;
}

.ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 1px #2D4D4C solid;
}
.profilePageDesktop_center .btn__session-list{
    text-align: right;
}
.profilePageDesktop_center .btn__session-list button {
    margin: 0 8px 16px 0;
}
.profilePageDesktop_center .btn__session-list button.btn_link {
    margin: 0 16px 16px 0;
}


/* === My Clients === */
/* --- Table with Sessions --- */
.my-clients__list-date .ant-table-thead > tr > th {
    padding: 0 16px 16px 16px;
    background: unset;
    border: 0;
    font-weight: 700;
}
.my-clients__list-date .ant-table-tbody > tr > td {
    padding: 8px 16px;
    font-weight: 300;
}

@media screen and (max-width:1440px) {
    .profilePageDesktop_center .calendarPageDesktop-month, .calendarPageDesktop-day > div {
        font-size: 14px;
    }
    .profilePageDesktop_center .btn__session-list button {
        font-size: 14px;
    }
}

@media screen and (max-width:1240px) {
.profilePageDesktop_center .calendarPageDesktop-week {
    font-size: 14px;
}
}
@media screen and (max-width:1140px) {
.profilePageDesktop_center .calendarPageDesktop-week {
    font-size: 12px;
    padding: 12px 5px 10px 5px;
}
}