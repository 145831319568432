.cleverPsychoListPageDesktop-wrapper {
    padding: 100px;
    background-color: #e5e3dc;
    min-height: 100%;
}

.cleverPsychoListPageDesktop-loading {
    position: absolute;
    z-index: 1000;
    top: 45%;
    left: 45%;
}

.cleverPsychoListPageDesktop-wrapper .ant-progress-steps-item {
    width: 15vw!important;
}

.cleverPsychoListPageDesktop-wrapper .ant-progress-text {
    display: none!important;
}

.cleverPsychoListPageDesktop-wrapper .ant-form-item-explain {
    display: none!important;
}

.cleverPsychoListPageDesktop-wrapper .block-icon {
    height: 5vh;
    margin-bottom: 2vh;
}