.psychoSelect-wrapper {
    background-color: #e5e3dc;
    border-radius: 25px 25px 0px 0px;
    color: #ffffff;
}
.psychoSelect-header-wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    position: relative;
    z-index: 1;
    background-color: #2d4d4c;
    border-radius: 16px 16px 0 0;
    padding: 24px 24px 72px 30px;
}
.mobi-logo_icon {
    width: 18px;
    height: auto;
}
.psychoSelect-header-img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    overflow: hidden;
}
.psychoSelect-header-logo {
    width: 100%;
}
.psychoSelect-header-column {
    display: flex;
    flex-direction: column;
    width: calc(100% - 110px);
    padding: 6px 0 0 28px;
}
.psychoSelect-header-btn {
    margin: 0 0 16px 0;
}
.psychoSelect-header-btn .ant-anchor-link {
    padding: 0;
}
.psychoSelect-header-btn .ant-anchor-ink {
    display: none;
}
.psychoSelect-header-row {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin: 0 0 9px 0;
}
.psychoSelect-header-row-label {
    font-weight: 300;
    font-size: 14px;
    line-height: 1;
}
.psychoSelect-header-row-value {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    padding: 0 0 0 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #E5E3DC;
}

.psychoSelect-header-name {
    margin: 0 0 5px 0;
    font-size: 20px;
}

.psychoSelect-body-wrapper {
    position: relative;
    z-index: 2;
    background-color: #e5e3dc;
    border-radius: 16px 16px 0 0;
    color: #2d4d4c;
    margin: -40px 0 0 0;
    padding: 36px 29px;
    display: flex;
    flex-direction: column;
}

.psychoSelect-body-title {
    font-weight: bold;
    color: #2d4d4c;
    font-size: 18px;
    margin-bottom: 10px;
}

.psychoSelect-body-row {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin: 0 0 12px 0;
}
.psychoSelect-body-col, .psychoSelect-body-row {
    margin: 0 0 32px 0;
}

.psychoSelect-body-row-label, .psychoSelect-body-col-label {
    font-weight: bold;
    color: #2d4d4c;
    font-size: 18px;
    line-height: 1;
}
.psychoSelect-body-row-label {
    width: 100px;
}
.psychoSelect-body-col-label {
    margin: 0 0 30px 0;
}
.psychoSelect-body-row .psychoSelect-body-row-label {
    font-size: 14px;
}
.psychoSelect-body-row-value, .psychoSelect-body-col-value {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    /* text-overflow: ellipsis; */
    /* overflow: hidden;
    white-space: nowrap; */
}
.psychoSelect-body-row-value {
    padding: 0 0 0 32px;
}
.psychoSelect-body-row-value div, .psychoSelect-body-col-value div {
    display: inline-block;
    margin: 0 8px 8px 0;
    padding: 8px 16px;
    background-color: #fff;
    border-radius: 8px;
}

.psychoSelect-body-button-primary {
    color: #ffffff;
    background-color: #EE836F;
    padding: 0px 10px;
    width: 42vw;
    border-radius: 10px;
    font-weight: bold;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.psychoSelect-body-button-default {
    color: #2d4d4c;
    background-color: #ffffff;
    padding: 0px 10px;
    border-radius: 10px;
    font-weight: bold;
    word-wrap: break-word;
    width: 42vw;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.2;
}

.psychoSelect-body-row-value-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 100px);
}

.psychoSelect-header-edit {
    height: 20px;
    width: 20px;
    margin-left: 10px;
}

.psychoSelect-select { 
    border: none;
    width: 50vw;
    border-radius: 0;
    background-color: #e5e3dc;
    border-bottom: 1px solid;
}

.psychoSelect-select > select { 
    -webkit-appearance: none;
    -moz-appearance: none; 
    appearance: none; 
    position: absolute; 
    top: -9999px; 
}

.psychoSelect-body-slot-wrapper {
    padding: 2px 12px;
    margin: 0 5px 5px 0;
    border-radius: 5px;
    border: 1px solid;
    font-size: 12px;
}

.psychoSelect-body-slot-wrapper-selected {
    padding: 2px 12px;
    margin: 0 5px 5px 0;
    border-radius: 5px;
    border: 1px solid #EE836F;
    color: #fff;
    font-size: 12px;
    background-color: #EE836F;
}

.psychoSelect-body-show-more-button {
    padding: 5px 10px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid #EE836F;
    width: fit-content;
    color: #fff;
    background-color: #EE836F;
}
.psychoSelect-mobi-zapis__item {
    margin: 0 0 30px 0;
}
.psychoSelect-mobi-zapis__date {
    margin: 0 0 6px 0;
    font-size: 14px;
    line-height: 1;
}