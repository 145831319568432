.clientGroupSessionsPageMobile-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #e5e3dc;
    height: 100%;
}

.clientGroupSessionsPageMobile-title {
    color: #2d4d4c;
    font-size: 26px;
    text-align: center;
    width: 100%;
    line-height: 27px;
    font-weight: 700;
    padding: 17px 32px 0 32px;
    margin-bottom: 10px;
}
.clientGroupSessionsPageMobile-helptext {
    margin: 0 0 16px 0;
    padding: 0 30px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
    text-align: center;
}
.clientGroupSessionsPageMobile-card {
    width: calc(100% - 32px);
    margin: 0 auto 32px auto;
    background-color: #f5f4f1;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 18px 18px 0 18px;
}

.clientGroupSessionsPageMobile-card-row {
    display: flex;
    flex-direction: row;
    margin: 0 0 16px 0;
}
.clientGroupSessionsPageMobile-card-row-play {
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.clientGroupSessionsPageMobile-card-row-play .btn-default {
    margin: 0 8px 0 0;
}
.clientGroupSessionsPageMobile-card-row-play .go-to-meet {
    margin: 0 0 0 10px;
}
.clientGroupSessionsPageMobile-card-specialist-name {
    color: #2d4d4c;
    font-weight: bold;
    line-height: 15px;
    font-size: 14px;
    padding: 6px 0 0 0;
}
.clientGroupSessionsPageMobile-card-specialist-trainer {
    font-size: 12px;
    line-height: 1;
}
.clientGroupSessionsPageMobile-card-img {
    background: #8d8d8d;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
}
.clientGroupSessionsPageMobile-card-column {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 12px;
}

.clientGroupSessionsPageMobile-card-logo {
    width: 100%;
}

.clientGroupSessionsPageMobile-card-who {
    width: calc(100% - 145px);
}

.clientGroupSessionsPageMobile-card-when {
    width: 85px;
}
.clientGroupSessionsPageMobile-card-date {
    color: #2d4d4c;
    font-size: 16px;
}

.clientGroupSessionsPageMobile-card-time {
    padding: 2px 0;
    font-size: 8px;
    color: #fff;
    background-color: #2d4d4c;
    border-radius: 10px;
    text-align: center;
}

.clientGroupSessionsPageMobile-card-description {
    padding: 0 0 18px 0;
    line-height: 16px;
    font-size: 12px;
    font-weight: 300;
    border-bottom: 1px solid #637B7B;
}

.clientGroupSessionsPageMobile-card-name {
    color: #2d4d4c;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
}



.clientGroupSessionsPageMobile-header-row {
    display: flex;
    flex-direction: row;
}

.clientGroupSessionsPageMobile-card-param {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    padding: 12px 0 0 0;
}
.clientGroupSessionsPageMobile-card-param__left {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: calc(100% - 75px);
    padding: 0 0 10px 0;
}
.clientGroupSessionsPageMobile-card-spec {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.clientGroupSessionsPageMobile-card-spec span {
    display: inline-block;
    padding: 5px 10px;
    background-color: #D5DBDB;
    font-size: 8px;
    text-align: center;
    color: #2d4d4c;
    border-radius: 16px;
}
.clientGroupSessionsPageMobile-card-hour {
    width: calc(30% - 8px);
}
.clientGroupSessionsPageMobile-card-period {
    width: 40%;
}
.clientGroupSessionsPageMobile-card-format {
    width: calc(30% - 8px);
}
.clientGroupSessionsPageMobile-card-count {
    font-size: 14px;
}
.clientGroupSessionsPageMobile-card-count span {
    display: inline-block;
    margin: 0 5px 0 0;
    font-size: 12px;
}
.btn-round {
    width: 55px;
    height: 55px;
    border-radius: 14px;
    background-color: #EE836F;
    background-image: url(../../../assets/icons/group-arrow-right.svg);
    background-size: 17px auto;
    background-position: 50%;
    background-repeat: no-repeat;
}
.btn-round span {
    display: none;
}
.clientGroupSessionsPageMobile-card-button-primary {
    color: #ffffff;
    background-color: #EE836F;
    padding: 0px 10px;
    border-radius: 10px;
    font-weight: bold;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}

.clientGroupSessionsPageMobile-header-row {
    display: flex;
    flex-direction: row;
}

.clientGroupSessionsPageMobile-mobile-body-button-primary {
    color: #EE836F;
    /* background-color: #EE836F; */
    padding: 0px 10px;
    width: 42vw;
    border-radius: 0;
    font-weight: bold;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #EE836F;
    cursor: pointer;
}

.clientGroupSessionsPageMobile-mobile-body-button-default {
    color: #8d8d8d;
    /* background-color: #ffffff; */
    padding: 0px 10px;
    border-radius: 0;
    font-weight: bold;
    word-wrap: break-word;
    width: 42vw;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.2;
    border-bottom: 1px solid #8d8d8d;
    cursor: pointer;
}