.footer {
    padding: 140px 0 40px 0;
}
.center-1200_mini {
    width: 1000px;
    margin: 0 0 0 72px;
}
.othersHelp-title {
    margin: 0 0 64px 0;
    font-size: 2em;
    line-height: 42px;
    font-weight: 700;
}
.othersHelp-phones {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 0 104px 0;
}
.othersHelp-phonesColumn {
    width: calc(25% - 50px);
}
.othersHelp-h3 {
    margin: 0 0 24px 0;
    font-size: 1.125em;
    font-weight: 700;
    line-height: 24px;
}
.othersHelp-phonesColumn p {
    margin: 0 0 8px 0;
    font-size: 1em;
    line-height: 24px;
    font-weight: 700;
    color: #748989;
}
.icon-social {
    list-style: none;
    margin: 0;
    padding: 0;
}
.icon-social_tel {
    width: 26px;
    height: 26px;
}
.icon-social_tel a {
    display: block;
}
.icon-social_tel a img {
    width: 100%;
}

.footerUnd {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.footerUnd-columnLeft {
    width: 290px;
}
.footerUnd-columnCenter {
    width: calc(100% - 650px);
}
.footerUnd-columnRight {
    width: 360px;
    padding: 0 0 0 80px;
}
.footerUnd-column p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
}
.footerUnd-column p strong {
    font-weight: 700;
}

@media screen and (max-width:1450px) {
    .footer {
        padding: 80px 32px 40px 0 !important;
    }
    .center-1200_mini {
        margin: 0 0 0 40px !important;
    }
    .othersHelp-wrapper {
        padding: 0;
    }
    .othersHelp-title {
        font-size: 24px;
        line-height: 28px;
    }
    .othersHelp-phones {
        margin: 0 0 64px 0;
    }
    .othersHelp-h3 {
        font-size: 16px;
        line-height: 20px;
    }
    .othersHelp-phonesColumn p {
        font-size: 14px;
        line-height: 20px;
    }
}
@media screen and (max-width:1200px) {
.footer {
    padding: 60px 32px 20px 0 !important;
}
.center-1200_mini {
    margin: 0 !important;
    padding: 60px 40px 24px 40px !important;
}
.footer.center-1200 {
    width: 100%;
    padding: 40px 32px 40px 32px !important;
}
.footerUnd {
    display: block;
}
}