.clientSessionsPageMobile-wrapper {
    width: 100%;
    border-radius: 25px 25px 0px 0px;
    color: #ffffff;
}
.clientSessionsPageMobile-header-row {
    display: flex;
    flex-direction: row;
    padding: 26px 30px 16px 30px;
}
.clientSessionsPageMobile-header-wrapper {
    border-radius: 15px 15px 0 0;
    background-color: #2d4d4c;
}

.clientSessionsPageMobile-header-column {
    display: flex;
    flex-direction: column;
    width: calc(100% - 110px);
    padding: 10px 0 0 28px;
}
.clientSessionsPageMobile-header-img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    overflow: hidden;
}
.clientSessionsPageMobile-header-logo {
    width: 100%;
}

.clientSessionsPageMobile-header-row-label {
    font-weight: 300;
}

.clientSessionsPageMobile-header-row-value {

}

.clientSessionsPageMobile-header-name {
    margin: 0 0 3px 0;
    font-size: 20px;
    line-height: 23px;
}
.clientSessionsPageMobile-header-count {
    margin: 0 0 24px 0;
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
    color: #9CA7A7;
}
.clientSessionsPageMobile-header-title-session {
    font-size: 16px;
    line-height: 1;
    color: #E5E3DC;
}
.clientSessionsPageMobile-header-title-date {
    font-size: 16px;
    font-weight: 700;
    color: #EE836F;
}

.clientSessionsPageMobile-header-btn {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 30px 40px 168px;
}
.clientSessionsPageMobile-header-btn .btn_transparent {
    width: calc(50% - 10px);
    padding: 8px 10px 6px 10px;
    border: 1px #AAB4B4 solid;
    color: #E5E3DC;
}
.clientSessionsPageMobile-header-btn .btn_startsession {
    width: 100%;
    margin: 16px 0 0 0;
}

.clientSessionsPageMobile-body-row-value-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.clientSessionsPageMobile-body-wrapper {
    background-color: #e5e3dc;
    border-radius: 25px 25px 0px 0px;
    height: 100%;
    color: #2d4d4c;
    padding: 38px 30px;
    display: flex;
    flex-direction: column;
}

.clientSessionsPageMobile-body-title {
    font-weight: bold;
    color: #2d4d4c;
    font-size: 16px;
    margin-bottom: 10px;
}

.clientSessionsPageMobile-body-row {
    margin: 0 0 30px 0;
}

.clientSessionsPageMobile-body-row-label {
    margin: 0 0 16px 0;
    font-weight: bold;
    color: #2d4d4c;
    line-height: 1;
}

.clientSessionsPageMobile-body-row-value {
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
}

